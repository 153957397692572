
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { ResourcesLocation } from '@/types/manholeManage'

@Component({
  components: { MapLocation }
})

export default class RestaurantAdd extends Vue {
  private info = {
    manholeName: '',
    manholeType: '',
    projectId: '',
    areaId: '',
    longitude: '',
    latitude: ''
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    manholeType: [
      { required: true, message: '请选择井盖类型', trigger: 'change' }
    ],
    manholeName: [
      { required: true, message: '请输入井盖编号', trigger: 'change' }
    ],
    areaId: [
      { required: true, message: '请选择区域', trigger: 'change' }
    ],
    longitude: [
      { required: true, message: '请地图打点', trigger: 'change' }
    ]
  }

  private areaList = []
  private typeList = []

  private submitShow = false
  private resourcesLocationList: ResourcesLocation[] | null = null
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getTypeList()
    this.$route.params.id && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.manholeManage.selectManholeByManholeId, {
      manholeId: this.$route.params.id
    }).then(res => {
      this.info = res || {}
      if (res) {
        this.getAreaList(res.projectId)
        this.resourcesLocationList = [{
          amount: 1,
          longitude: res.longitude,
          latitude: res.latitude
        }]
      }
    })
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'manholeType'
    }).then(res => {
      this.typeList = res.manholeType || []
    })
  }

  getAreaList (projectId: string) {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId,
      notAllArea: '1'
    }).then(res => {
      this.areaList = res.list || []
    })
  }

  projectChange (projectId: string) {
    this.info.areaId = ''
    this.areaList = []
    if (projectId) {
      this.getAreaList(projectId)
    }
  }

  locationChange (list: ResourcesLocation[]) {
    console.log(list)
    if (list.length > 0) {
      this.info.longitude = list[0].longitude
      this.info.latitude = list[0].latitude
    }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.id ? this.$apis.manholeManage.updateManhole : this.$apis.manholeManage.insertManhole
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'manholeManageList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
